import Service from "./service";

class FileService extends Service {
  constructor() {
    super("files");
  }

  async get(id = '', data = {}) {
    const endpoint = (id !== '') ? `${this.endpoint}/${id}` : this.endpoint;

    try {
      const response = await this.api.get(endpoint, {
        params: data,
        responseType: 'blob'
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default FileService;
