import Swal from "sweetalert2";

const verifySession = (_) => {
  return localStorage.getItem("token");
};

const clearSession = (router, expired = false) => {
  if (expired)
    Swal.fire({
      title: "La sesión ha caducado",
      text: "Inicie nuevamente su sesión",
      icon: "warning",
      confirmButtonText: "Aceptar",
    });

  localStorage.removeItem("token");
  localStorage.removeItem("user");

  router.push("/auth/login");
};

const isValidSession = (_) => {
  return localStorage.getItem("token") ? true : false;
};

const getToken = (_) => {
  return localStorage.getItem("token");
};

const getUserData = (_) => {
  const user =
    localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  if (user == null) clearSession();

  return user;
};

const getBusinessId = (_) => {
  const user = getUserData();

  return user.business_id;
};

const showAlertProcessDoc = (_) => {
  return Swal.fire({
    icon: "info",
    title: "Cargando...",
    text: "Por favor, espere mientras procesamos su factura. No cierre esta pestaña ni la ventana para evitar posibles errores.",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showConfirmButton: false,
    showCancelButton: false,
    backdrop: true,
    didOpen: () => {
      Swal.showLoading();
    },
  });
};

const showAlertInternalServerErr = (_) => {
  return Swal.fire({
    text: 'Error interno del servidor',
    icon: 'error',
    confirmButtonText: "Aceptar",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  })
};

const showAlertSuccessChanges = (_) => {
  return Swal.fire({
    title: "Cambios guardados exitosamente",
    icon: "success",
    confirmButtonText: "Aceptar",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

const showAlertSuccessCreated = (_) => {
  return Swal.fire({
    title: "Registro creado exitosamente",
    icon: "success",
    confirmButtonText: "Aceptar",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

const showAlertSubscriptionError = (router, res) => {
  router.push('/admin/settings');

  return Swal.fire({
    title: res.errors.subscription.title,
    text: res.errors.subscription.message,
    icon: "info",
    confirmButtonText: "Aceptar",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
}

const generateRandomString = length => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  
  for (let i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  
  return result;
}

export {
  verifySession,
  clearSession,
  isValidSession,
  getToken,
  getUserData,
  getBusinessId,
  showAlertProcessDoc,
  showAlertInternalServerErr,
  showAlertSuccessChanges,
  showAlertSuccessCreated,
  showAlertSubscriptionError,
  generateRandomString
};
