import Service from "./service";

class BusinessService extends Service {
  constructor() {
    super("business");
  }

  async postFiles(id, data) {
    const endpoint = `${this.endpoint}/${id}`;

    try {
      const response = await this.api.post(endpoint, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      if (!error.response) throw error;

      const { data, status } = error.response;
      throw { data, status };
    }
  }
}

export default BusinessService;
