<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="rounded-t mb-0 px-4 py-2 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <button
            class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="toggleModal"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="bg-purple-100">
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Código
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Dirección
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Matriz
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Estado
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="establishment in establishments" :key="establishments.id">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ establishment.code }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ establishment.address }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <i
                class="fas fa-circle mr-2"
                :class="
                  establishment.main ? 'text-purple-500' : 'text-orange-500'
                "
              ></i>
              {{ establishment.main ? "SI" : "NO" }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <i
                class="fas fa-circle mr-2"
                :class="establishment.open ? 'text-lime-500' : 'text-red-500'"
              ></i>
              {{ establishment.open ? "Abierto" : "Cerrado" }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <button
                class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleEdit(establishment.id)"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleDelete(establishment.id)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <EstablishmentsModal
    :showModal="showModal"
    :establishmentId="establishmentId"
    @toggleModal="toggleModal"
    @reloadEstablishments="_ => emit('reload')"
  />
</template>
<script setup>
import { onMounted, defineProps, defineEmits, ref } from "vue";

import EstablishmentService from "@/services/establishmentService";

import EstablishmentsModal from "@/components/Modals/EstablishmentsModal.vue";
import Swal from "sweetalert2";

const establishmentId = ref(null);
const showModal = ref(false);

const emit = defineEmits([ 'reload' ]);
const props = defineProps({
  establishments: Array,
});

const service = new EstablishmentService();
const { establishments } = props;

onMounted((_) => {
  //getData();
});

const handleEdit = (id) => {
  establishmentId.value = id;

  toggleModal();
};

const handleDelete = async (id) => {
  const confirmResult = await Swal.fire({
    title: "¿Desea confirmar la eliminación?",
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
  });

  if (confirmResult.isConfirmed) {
    try {
      await service.delete(id);
      
      emit('reload')
    } catch (error) {
      const { data, status } = error;
      if (status === 401) return clearSession(router, true);
    }
  }
};

const toggleModal = (_) => {
  showModal.value = !showModal.value;

  if (showModal.value === false) establishmentId.value = null;
};
</script>
