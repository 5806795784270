<template>
  <div>
    <navbar />
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          :style="`background-image: url('${registerBg2}');`"
        ></div>
        <router-view />
        <footer-small absolute />
      </section>
    </main>
  </div>
</template>
<script setup>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterSmall from "@/components/Footers/FooterSmall.vue";

import registerBg2 from "@/assets/img/register_bg_2.png";

import { onBeforeMount } from "vue";
import { useRouter } from 'vue-router';

import { isValidSession } from '@/helpers/utils.js';

const router = useRouter();

onBeforeMount(() => {
  if (isValidSession())
    router.push('/admin/panel');
});
</script>
