<template>
  <LoadingScreen />
</template>
<script setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";

import Swal from 'sweetalert2';

import LoadingScreen from "@/components/LoadingScreen.vue";

import { verifyPay } from "@/services/payphoneService.js";
import PayService from "@/services/payService.js";
import { profile } from "@/services/authService";

const route = useRoute();
const router = useRouter();

const service = new PayService();

const id = route.query.id;
const clientTransactionId = route.query.clientTransactionId;

onMounted(() => {
  verify();
});

const verify = async () => {
  try {
    const payPhoneRes = await verifyPay({ id, clientTxId: clientTransactionId });
    const backRes = await service.post(payPhoneRes);

    localStorage.setItem('payphone_res', JSON.stringify(payPhoneRes));
    localStorage.setItem('backend_res', JSON.stringify(backRes));

    const profileRes = await profile();

    localStorage.setItem('user', JSON.stringify(profileRes));
  } catch (error) {
    const { data, status } = error
    //console.log(data);
  } finally {
    router.push('/admin/settings');
  }
}

</script>
