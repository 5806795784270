<template>
  <div class="flex flex-wrap border p-2">
    <div class="w-full flex justify-end mb-1">
      <button
        class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        @click="handleDelete"
      >
        <i class="fas fa-trash"></i>
      </button>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-6/12">
        <div class="px-2">
          <label class="text-sm"
            >Producto<span class="text-red-500">*</span></label
          >
          <Select
            v-model="item.itemId"
            :options="products"
            filter
            optionLabel="name"
            optionValue="id"
            placeholder="Seleccione..."
            class="w-full my-2"
            @change="handleChange"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex items-center">
                <div>
                  {{ getProductById(slotProps.value).code }} -
                  {{ getProductById(slotProps.value).name }}
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex items-center">
                <div>
                  {{ slotProps.option.code }} - {{ slotProps.option.name }}
                </div>
              </div>
            </template>
          </Select>
        </div>
        <div class="px-2">
          <label class="text-sm"
            >Detalle<span class="text-red-500">*</span></label
          >
          <textarea
            class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{
              'border-red-500': hasError(errDetail) && item.itemId !== '0',
            }"
            data-height="50"
            v-model="item.detail"
          ></textarea>
        </div>
      </div>
      <div class="w-full xl:w-2/12 px-2">
        <label class="text-sm"
          >Descuento (%)<span class="text-red-700">*</span></label
        >
        <input
          type="number"
          class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
          :class="{
            'border-red-500': hasError(errDiscount) && item.itemId !== '0',
          }"
          min="0"
          max="100"
          v-model="item.discount"
          @change="handleUpdateValues"
        />
      </div>
      <div class="w-full xl:w-2/12 px-2">
        <label class="text-sm"
          >Cantidad<span class="text-red-700">*</span></label
        >
        <input
          type="number"
          class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
          :class="{
            'border-red-500': hasError(errAmount) && item.itemId !== '0',
          }"
          min="1"
          v-model="item.amount"
          @change="handleUpdateValues"
        />
      </div>
      <div class="w-full xl:w-2/12 px-2">
        <label class="text-sm"
          >Precio ($)<span class="text-red-700">*</span></label
        >
        <input
          type="text"
          class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
          :class="{
            'border-red-500': hasError(errPrice) && item.itemId !== '0',
          }"
          readonly
          v-model="item.price"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, defineEmits, onUpdated } from "vue";

import Select from "primevue/select";

const emit = defineEmits(["changeItem", "updateValues", "deleteItem"]);

const props = defineProps({
  item: Object,
  products: Array,
  index: Number,
  errors: Object,
});

const { item, products, index } = props;

const errItem = "items." + index + ".item_id";
const errAmount = "items." + index + ".amount";
const errDetail = "items." + index + ".detail";
const errDiscount = "items." + index + ".discount";
const errPrice = "items." + index + ".price";

onMounted(() => {
  //
});

onUpdated(() => {
  //
});

const handleChange = (event) => {
  emit("changeItem", index);
};

const handleUpdateValues = (event) => {
  emit("updateValues");
};

const handleDelete = () => {
  emit("deleteItem", index);
};

const hasError = (fieldName) => {
  return props.errors.hasOwnProperty(fieldName);
};

function getProductById(id) {
  return products.find(product => product.id === id) || {};
}
</script>
