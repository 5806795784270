<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">Registro</h6>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="handleSubmit">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Número de identificación (Cédula, RUC o Pasaporte)
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  
                  placeholder="Cédula, RUC o Pasaporte"
                  v-model="document"
                />
                <div
                  v-if="validationErrors.document"
                  class="text-red-500 text-xs mt-1"
                >
                  {{ validationErrors.document }}
                </div>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="name"
                />
                <div
                  v-if="validationErrors.name"
                  class="text-red-500 text-xs mt-1"
                >
                  {{ validationErrors.name }}
                </div>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Apellido
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="lastname"
                />
                <div
                  v-if="validationErrors.lastname"
                  class="text-red-500 text-xs mt-1"
                >
                  {{ validationErrors.lastname }}
                </div>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Nombre de Usuario
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="username"
                />
                <div
                  v-if="validationErrors.username"
                  class="text-red-500 text-xs mt-1"
                >
                  {{ validationErrors.username }}
                </div>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Correo electrónico
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="email"
                />
                <div
                  v-if="validationErrors.email"
                  class="text-red-500 text-xs mt-1"
                >
                  {{ validationErrors.email }}
                </div>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Contraseña
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="password"
                />
                <div
                  v-if="validationErrors.password"
                  class="text-red-500 text-xs mt-1"
                >
                  {{ validationErrors.password }}
                </div>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Confirmar Contraseña
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="passwordConfirm"
                />
                <div
                  v-if="validationErrors.password_confirmation"
                  class="text-red-500 text-xs mt-1"
                >
                  {{ validationErrors.password_confirmation }}
                </div>
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    v-model="policy"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Estoy de acuerdo con la
                    <a href="javascript:void(0)" class="text-purple-500">
                      Política de Privacidad
                    </a>
                  </span>
                </label>
              </div>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    v-model="dataProtection"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Estoy de acuerdo con la
                    <a href="javascript:void(0)" class="text-purple-500">
                      Ley de Protección de Datos
                    </a>
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Crear Cuenta
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/login" class="text-blueGray-200">
              <small>Iniciar Sesión</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from "sweetalert2";
import { onMounted, ref } from "vue";

import { register } from "@/services/authService";
import { useRouter } from "vue-router";

const router = useRouter();

const username = ref("");
const email = ref("");
const document = ref("");
const name = ref("");
const lastname = ref("");
const password = ref("");
const passwordConfirm = ref("");
const policy = ref(false);
const dataProtection = ref(false);

const validationErrors = ref([]);

onMounted((_) => {
  //
});

const handleSubmit = async (_) => {
  if (!policy.value || !dataProtection.value)
    return Swal.fire({
      text: "Debe aceptar los términos y condiciones.",
      icon: "error",
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });

  const reqData = {
    document: document.value,
    name: name.value,
    lastname: lastname.value,
    username: username.value,
    email: email.value,
    password: password.value,
    password_confirmation: passwordConfirm.value,
  };

  try {
    const resRegister = await register(reqData);

    Swal.fire({
      title: "Se ha registrado correctamente.",
      text: "Inicie sesión con los datos proporcionados.",
      icon: "success",
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });

    router.push("/auth/login");
  } catch (error) {
    const { data, status } = error;

    if (status == 400 && data.errors && data.errors.validation) {
      await Swal.fire({
        title: "Campos incorrectos y/o faltantes",
        text: "Verifique los campos",
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      const { errors } = data;

      let auxErrors = {};
      errors.validation.forEach((validationError) => {
        if (auxErrors.hasOwnProperty(validationError.field))
          auxErrors[validationError.field] += validationError.message;
        else auxErrors[validationError.field] = validationError.message;
      });

      validationErrors.value = auxErrors;
    }
  }
};
</script>
