<template>
  <LoadingScreen v-if="loading" />
  <div
    v-else
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="block w-full overflow-x-auto">
      <DataTable
        v-model:filters="filters"
        :value="customers"
        paginator
        :rows="15"
        dataKey="id"
        filterDisplay="row"
        :loading="loading"
        :globalFilterFields="['identification', 'code', 'businessName', 'address', 'cellphone', 'email']"
        class="custom-header"
      >
        <template #header>
          <div class="flex justify-between">
            <button
              class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="toggleModal"
            >
              Agregar
            </button>
            <IconField>
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText
                v-model="filters['global'].value"
                placeholder="Buscar..."
              />
            </IconField>
          </div>
        </template>
        <Column field="identification" header="CI/RUC" :filter="true">
          <template #body="{ data }">
            <span class="text-sm">{{ data.identification }}</span>
          </template>
        </Column>
        <Column field="businessName" header="Razón Solcial" :filter="true">
          <template #body="{ data }">
            <span class="text-sm">{{ data.businessName }}</span>
          </template>
        </Column>
        <Column field="address" header="Dirección" :filter="true">
          <template #body="{ data }">
            <span class="text-sm">{{ data.address }}</span>
          </template>
        </Column>
        <Column field="cellphone" header="Teléfono" :filter="true">
          <template #body="{ data }">
            <span class="text-sm">{{ data.cellphone }}</span>
          </template>
        </Column>
        <Column field="email" header="Email" :filter="true">
          <template #body="{ data }">
            <span class="text-sm">{{ data.email }}</span>
          </template>
        </Column>
        <Column field="tax" header="Acciones" :filter="true">
          <template #body="{ data }">
            <div v-if="data.identification !== '9999999999999'">
              <button
                class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleEdit(data.id)"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleDelete(data.id)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <div v-else>...</div>
          </template>
        </Column>
        <template #empty> No se encontraron clientes. </template>
        <template #loading> Cargando clientes. Espere por favor. </template>
      </DataTable>
    </div>
  </div>

  <CustomerModal
    :showModal="showModal"
    :customerId="customerId"
    :identificationTypes="identificationTypes"
    @toggleModal="toggleModal"
    @reloadCustomers="getData"
  />
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import CustomerService from "@/services/customerService";
import IdentificationTypeService from "@/services/identificationTypeService";

import LoadingScreen from "@/components/LoadingScreen.vue";
import CustomerModal from "@/components/Modals/CustomerModal";

import { clearSession, getBusinessId } from "@/helpers/utils";
import Swal from "sweetalert2";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "@primevue/core/api";
import InputText from "primevue/inputtext";

const router = useRouter();

const loading = ref(true);

const showModal = ref(false);
const service = new CustomerService();
const identificationTypeService = new IdentificationTypeService();
const customers = ref([]);
const customerId = ref(null);
const identificationTypes = ref([]);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  identification: { value: null, matchMode: FilterMatchMode.CONTAINS },
  businessName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  address: { value: null, matchMode: FilterMatchMode.CONTAINS },
  cellphone: { value: null, matchMode: FilterMatchMode.CONTAINS },
  email: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

onMounted(() => {
  getData();
});

const getData = async () => {
  const reqData = { business_id: getBusinessId() };

  try {
    loading.value = true;

    customers.value = await service.get("", reqData);
    identificationTypes.value = await identificationTypeService.get();
  } catch (error) {
    const { status } = error;

    if (status === 401) return clearSession(router, true);
  } finally {
    loading.value = false;
  }
};

const toggleModal = (_) => {
  showModal.value = !showModal.value;

  if (showModal.value === false) customerId.value = null;
};

const handleEdit = (id) => {
  customerId.value = id;

  toggleModal();
};

const handleDelete = async (id) => {
  const confirmResult = await Swal.fire({
    title: "¿Desea confirmar la eliminación?",
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
  });

  if (confirmResult.isConfirmed) {
    try {
      const reqData = { business_id: getBusinessId() };

      await service.delete(id, reqData);
      getData();
    } catch (error) {
      const { data, status } = error;
      if (status === 401) return clearSession(router, true);

      if (status == 400 && data.errors && data.errors.constraint_violation) {
        await Swal.fire({
          text: data.errors.constraint_violation.join(", "),
          icon: "warning",
          confirmButtonText: "Aceptar",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
      }
    }
  }
};
</script>
