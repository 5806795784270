<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="h-12 text-sm xl:text-white text-blueGray-700 inline-flex items-center justify-center"
        >
          {{ username }}
        </span>
        <!--<span
          class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        >
          <img
            alt="..."
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          />
        </span>-->
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        to="/admin/settings"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="toggleDropdown"
      >
        Perfil
      </router-link>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <button
        @click="finishSession"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Cerrar Sesión
      </button>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

import image from "@/assets/img/team-1-800x800.jpg";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
      username: this.getUsername()
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    getUsername: function () {
      const user = JSON.parse(localStorage.getItem("user"))
      return (user != null) ? user.name + ' ' + user.lastname : '';
    },
    finishSession() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      Swal.fire({
        title: 'Se ha cerrado la sesión exitosamente',
        icon: 'success'
      });

      this.$router.push("/auth/login");
    }
  },
};
</script>
