<template>
  <LoadingScreen v-if="loading" />
  <div v-else>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <SectionHeader title="Mi Comercio" />
      </div>
      <div class="w-full mb-4 px-4">
        <SectionTitle title="Configuración" />
      </div>
      <div class="w-full mb-12 px-2 flex flex-col lg:flex-row">
        <div class="w-full lg:w-3/4 mx-1 px-2 order-2 lg:order-1">
          <CardCommerce :business="business" />
        </div>
        <div class="w-full lg:w-1/4 mx-1 px-2 order-1 lg:order-2">
          <CardSequences :sequences="sequences" />
        </div>
      </div>
      <div class="w-full mb-4 px-4">
        <SectionTitle title="Establecimientos" />
      </div>
      <div class="w-full mb-4 px-4">
        <CardEstablishments :establishments="establishments" @reload="getData" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router';

import BusinessService from '@/services/businessService';
import EstablishmentService from '@/services/establishmentService';
import SequenceService from '@/services/sequenceService';

import CardSequences from '@/components/Cards/CardSequences.vue';
import CardCommerce from '@/components/Cards/CardCommerce.vue';
import SectionHeader from "@/components/Cards/SectionHeader.vue";
import SectionTitle from "@/components/Cards/SectionTitle.vue";
import CardEstablishments from '@/components/Cards/CardEstablishments.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';

import { clearSession, getBusinessId } from "@/helpers/utils";
const router = useRouter();

const loading = ref(true);

const service = new BusinessService();
const establishmentService = new EstablishmentService();
const sequenceService = new SequenceService();

const business = ref({});
const establishments = ref([]);
const sequences = ref([]);

onBeforeMount(_ => {
  getData();
});

const getData = async _ => {
  const reqData = { business_id: getBusinessId() };

  try {
    loading.value = true;

    business.value = await service.get(getBusinessId());
    establishments.value = await establishmentService.get('', reqData);
    sequences.value = await sequenceService.get('', reqData);
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);
  } finally {
    loading.value = false;
  }
}

</script>
