import axios from 'axios';
import { backendBaseUri } from '@/helpers/constants.js'
import { getToken } from '@/helpers/utils.js'

class Service {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.api = axios.create({
      baseURL: backendBaseUri,
      headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
  }

  async get(id = '', data = {}) {
    const endpoint = (id != '') ? `${this.endpoint}/${id}` : this.endpoint

    try {
      const response = await this.api.get(endpoint, { params: data });
      return response.data;
    } catch (error) {
      if (!error.response)
        throw error

      const { data, status } = error.response
      throw { data, status }
    }
  }

  async post(data) {
    try {
      const response = await this.api.post(this.endpoint, data);
      return response.data;
    } catch (error) {
      if (!error.response)
        throw error

      const { data, status } = error.response
      throw { data, status }
    }
  }

  async put(id, data) {
    try {
      const response = await this.api.put(`${this.endpoint}/${id}`, data);
      return response.data;
    } catch (error) {
      if (!error.response)
        throw error

      const { data, status } = error.response
      throw { data, status }
    }
  }

  async delete(id, data = {}) {
    try {
      await this.api.delete(`${this.endpoint}/${id}`, { params: data });
    } catch (error) {
      if (!error.response)
        throw error

      const { data, status } = error.response
      throw { data, status }
    }
  }
}

export default Service;
