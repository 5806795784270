<template>
  <div>
    <Sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <AdminNavbar />
      <HeaderStats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view />
        <FooterAdmin />
      </div>
    </div>
  </div>
</template>
<script setup>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";

import { onBeforeMount } from "vue";
import { useRouter } from 'vue-router';

import { isValidSession } from '@/helpers/utils.js';

const router = useRouter();

onBeforeMount(() => {
  if (!isValidSession())
    router.push('/auth/login');
});
</script>
