import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import PrimeVue from "primevue/config";
import Aura from '@primevue/themes/aura';

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";
import Panel from "./views/admin/Panel.vue";
import Products from "./views/admin/Products.vue";
import Commerces from "./views/admin/Commerces.vue";
import Customers from "./views/admin/Customers.vue";
import NewDocument from "./views/admin/NewDocument.vue";
import Documents from "./views/admin/Documents.vue";
import VerifyPayment from "@/views/admin/VerifyPayment.vue";

// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/panel",
    component: Admin,
    children: [
      {
        path: "/admin/panel",
        redirect: "/admin/comercio",
        component: Panel,
      },
      {
        path: "/admin/comercio",
        component: Commerces,
      },
      {
        path: "/admin/productos",
        component: Products,
      },
      {
        path: "/admin/clientes",
        component: Customers,
      },
      {
        path: "/admin/facturacion",
        component: NewDocument,
      },
      {
        path: "/admin/facturas",
        component: Documents,
      },
      {
        path: "/admin/facturas/editar/:id",
        component: NewDocument,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/verify-payment",
        component: VerifyPayment,
      }
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  /*{
    path: "/landing",
    component: Landing,
  },*/
  /*{
    path: "/profile",
    component: Profile,
  },*/
  {
    path: "/",
    component: Landing,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.use(PrimeVue, {
  theme: {
      preset: Aura,
      options: {
        prefix: 'p',
        darkModeSelector: '.system',
        cssLayer: false
    }
  }
});
app.mount("#app");
