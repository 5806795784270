<template>
  <div
    v-if="props.showModal"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
      >
        <div
          class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
        >
          <h3 class="text-3xl font-semibold">Clientes</h3>
        </div>
        <div class="relative p-6 flex-auto">
          <div class="flex flex-wrap w-full">
            <div class="w-full xl:w-4/12 px-2">
              <label class="text-sm">Tipo de Identificación:</label
              ><span class="text-red-500">*</span>
              <select
                class="my-2 px-3 py-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                :class="{ 'border-red-500': validationErrors.itemTypeId }"
                v-model="identificationTypeId"
              >
                <option value="" selected>Seleccione...</option>
                <option
                  v-for="identificationType in props.identificationTypes"
                  :value="identificationType.id"
                  :key="identificationType.id"
                >
                  {{ identificationType.name }}
                </option>
              </select>
              <div
                v-if="validationErrors.identification_type"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.identification_type }}
              </div>
            </div>
            <div class="w-full xl:w-8/12 px-2">
              <label class="text-sm">Número de Identificación:</label
              ><span class="text-red-500">*</span>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="identification"
                :class="{ 'border-red-500': validationErrors.identification }"
                @change="handleSearch"
              />
              <div
                v-if="validationErrors.identification"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.identification }}
              </div>
            </div>
            <div class="w-full px-2">
              <label class="text-sm">Razón Social:</label
              ><span class="text-red-500">*</span>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="business_name"
                :class="{ 'border-red-500': validationErrors.business_name }"
              />
              <div
                v-if="validationErrors.business_name"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.business_name }}
              </div>
            </div>
            <div class="w-full px-2">
              <label class="text-sm">Dirección:</label
              ><span class="text-red-500">*</span>
              <textarea
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="address"
                rows="3"
                :class="{ 'border-red-500': validationErrors.address }"
              ></textarea>
              <div
                v-if="validationErrors.address"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.address }}
              </div>
            </div>

            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Celular:</label
              ><span class="text-red-500">*</span>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="cell_phone"
                :class="{ 'border-red-500': validationErrors.cell_phone }"
              />
              <div
                v-if="validationErrors.cell_phone"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.cell_phone }}
              </div>
            </div>
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Correo electrónico:</label
              ><span class="text-red-500">*</span>
              <input
                type="email"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="email"
                :class="{ 'border-red-500': validationErrors.email }"
              />
              <div
                v-if="validationErrors.email"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.email }}
              </div>
            </div>

            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Teléfono:</label>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="phone_number"
                :class="{ 'border-red-500': validationErrors.phone_number }"
              />
              <div
                v-if="validationErrors.phone_number"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.phone_number }}
              </div>
            </div>
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Extensión:</label>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="extension"
                :class="{ 'border-red-500': validationErrors.extension }"
              />
              <div
                v-if="validationErrors.extension"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.extension }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
        >
          <button
            class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="handleCancel"
          >
            Cancelar
          </button>
          <button
            class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="handleSave"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="props.showModal"
    class="opacity-25 fixed inset-0 z-40 bg-black"
  ></div>
</template>
<script setup>
import { onUpdated, defineProps, defineEmits, ref } from "vue";

import Swal from "sweetalert2";

import CustomerService from "@/services/customerService";

import { getBusinessId, showAlertSuccessCreated } from "@/helpers/utils";

const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
  customerId: {
    type: Number,
    required: false,
  },
  identificationTypes: {
    type: Array,
    required: true,
  },
});

let edit = false;

const service = new CustomerService();

const validationErrors = ref({});
const identificationTypeId = ref("");
const identification = ref("");
const business_name = ref("");
const address = ref("");
const phone_number = ref("");
const extension = ref("");
const cell_phone = ref("");
const email = ref("");

const emit = defineEmits(["toggleModal", 'reloadCustomers']);

onUpdated(() => {
  if (!edit) if (props.customerId !== null) getCustomer();
});

const getCustomer = async (_) => {
  const reqData = { business_id: getBusinessId() };

  try {
    const res = await service.get(props.customerId, reqData);

    const identificationType = props.identificationTypes.find(
      (type) => type.name == res.identificationType
    );

    identificationTypeId.value = identificationType.id;
    identification.value = res.identification;
    business_name.value = res.businessName;
    address.value = res.address;
    phone_number.value = res.phoneNumber;
    extension.value = res.phoneExtension;
    cell_phone.value = res.cellphone;
    email.value = res.email;
  } catch (error) {
    const { data, status } = error;

    if (status === 401) {
      if (props.showModal) toggleModal();

      return clearSession(router, true);
    }
  } finally {
    edit = true;
  }
};

const handleSearch = async event => {
  const aux = event.target.value;

  Swal.fire({
    icon: "info",
    title: "Cargando...",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showConfirmButton: false,
    showCancelButton: false,
    backdrop: true,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    const res = await service.get('', { identification: aux });

    if (res.identification) {
      identificationTypeId.value = res.identificationType.id;
      identification.value = res.identification;
      business_name.value = res.businessName;
      address.value = res.address;
      phone_number.value = res.phoneNumber;
      extension.value = res.phoneExtension;
      cell_phone.value = res.cellphone;
      email.value = res.email;
    }
  } catch (error) {
    const { data, status } = error;

    if (status === 401) {
      if (props.showModal) toggleModal();

      return clearSession(router, true);
    }
  } finally {
    Swal.close(); 
  }
}

const handleSave = async () => {
  const reqData = {
    business_id: getBusinessId(),
    identification_type_id: identificationTypeId.value,
    identification: identification.value,
    business_name: business_name.value,
    address: address.value,
    cellphone: cell_phone.value,
    email: email.value,
    phone_number: phone_number.value,
    phone_extension: extension.value
  };

  try {
    const res =
      props.customerId !== null
        ? await service.put(props.customerId, reqData)
        : await service.post(reqData);
    await showAlertSuccessCreated();

    edit = false;
    validationErrors.value = {};
    emit("reloadCustomers");
    handleCancel();
  } catch (error) {
    const { data, status } = error;

    if (status === 401) {
      if (props.showModal) toggleModal();

      return clearSession(router, true);
    }

    if (status == 400 && data.errors && data.errors.validation) {
      await Swal.fire({
        title: "Campos incorrectos y/o faltantes",
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      const { errors } = data;

      let auxErrors = {};
      errors.validation.forEach((validationError) => {
        auxErrors[validationError.field] = validationError.message;
      });

      validationErrors.value = auxErrors;
    }

    if (status == 400 && data.errors && data.errors.constraint_violation) {
      await Swal.fire({
        text: data.errors.constraint_violation.join(", "),
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
  }
};

const handleCancel = () => {
  identificationTypeId.value = "";
  identification.value = "";
  business_name.value = "";
  address.value = "";
  phone_number.value = "";
  extension.value = "";
  cell_phone.value = "";
  email.value = "";

  validationErrors.value = {};

  emit("toggleModal");
};
</script>
