<template>
  <div
    v-if="props.showModal"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <!--content-->
      <div
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
      >
        <!--header-->
        <div
          class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
        >
          <h3 class="text-3xl font-semibold">Establecimiento</h3>
        </div>
        <!--body-->
        <div class="relative p-6 flex-auto">
          <div class="flex flex-wrap w-full">
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Código:</label>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="code"
                :class="{ 'border-red-500': validationErrors.code }"
              />
              <div
                v-if="validationErrors.code"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.code }}
              </div>
            </div>
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm"
                >Estado:<span class="text-red-500">*</span></label
              >
              <select
                class="my-2 px-3 py-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                v-model="open"
                :class="{ 'border-red-500': validationErrors.state }"
              >
                <option value="" disabled>Seleccione...</option>
                <option value="1">Abierto</option>
                <option value="0">Cerrado</option>
              </select>
              <div
                v-if="validationErrors.state"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.state }}
              </div>
            </div>
            <div class="w-full px-2">
              <label class="text-sm">Dirección:</label>
              <textarea
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="address"
                :class="{ 'border-red-500': validationErrors.address }"
              ></textarea>
              <div
                v-if="validationErrors.address"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.address }}
              </div>
            </div>
            <div class="w-full p-2">
              <p>¿Establecimiento Matriz?</p>
              <div class="flex items-center my-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value="1"
                  name="default-radio"
                  class="w-4 h-4 text-purple-600"
                  v-model="main"
                />
                <label for="default-radio-1" class="ms-2 text-sm font-medium"
                  >Si</label
                >
              </div>
              <div class="flex items-center">
                <input
                  id="default-radio-2"
                  type="radio"
                  value="0"
                  name="default-radio"
                  class="w-4 h-4 text-purple-600"
                  v-model="main"
                />
                <label for="default-radio-2" class="ms-2 text-sm font-medium"
                  >No</label
                >
              </div>
            </div>
          </div>
        </div>
        <!--footer-->
        <div
          class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
        >
          <button
            class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="handleCancel"
          >
            Cancelar
          </button>
          <button
            class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="handleSave"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="props.showModal"
    class="opacity-25 fixed inset-0 z-40 bg-black"
  ></div>
</template>

<script setup>
import { onUpdated, defineProps, defineEmits, ref } from "vue";
import { useRouter } from "vue-router";

import EstablishmentService from "@/services/establishmentService";

import { getBusinessId, showAlertSuccessCreated } from "@/helpers/utils";
import Swal from "sweetalert2";

const router = useRouter();

const props = defineProps({
  establishmentId: {
    type: Number,
    required: false,
  },
  showModal: {
    type: Boolean,
    required: true,
  },
});

let edit = false;

const service = new EstablishmentService();

const validationErrors = ref({});
const code = ref("");
const address = ref("");
const open = ref("");
const main = ref(0);

const emit = defineEmits(["toggleModal", 'reloadEstablishments']);

onUpdated(() => {
  if (!edit) if (props.establishmentId !== null) getEstablishment();
});

const getEstablishment = async (_) => {
  const reqData = { business_id: getBusinessId() };

  try {
    const res = await service.get(props.establishmentId, reqData);

    address.value = res.address;
    code.value = res.code;
    open.value = res.open ? 1 : 0;
    main.value = res.main ? 1 : 0;
  } catch (error) {
    const { data, status } = error;

    if (status === 401) {
      if (props.showModal) toggleModal();

      return clearSession(router, true);
    }
  } finally {
    edit = true;
  }
};

const handleSave = async (_) => {
  const reqData = {
    business_id: getBusinessId(),
    code: code.value,
    address: address.value,
    open: open.value == 1,
    main: main.value == 1,
  };

  try {
    const res =
      props.establishmentId !== null
        ? await service.put(props.establishmentId, reqData)
        : await service.post(reqData);
    await showAlertSuccessCreated();

    edit = false;
    validationErrors.value = {};
    emit('reloadEstablishments');
    handleCancel();
  } catch (error) {
    const { data, status } = error;

    if (status === 401) {
      if (props.showModal) toggleModal();

      return clearSession(router, true);
    }

    if (status == 400 && data.errors && data.errors.validation) {
      await Swal.fire({
        title: "Campos incorrectos y/o faltantes",
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      const { errors } = data;

      let auxErrors = {};
      errors.validation.forEach((validationError) => {
        auxErrors[validationError.field] = validationError.message;
      });

      validationErrors.value = auxErrors;
    }

    if (status == 400 && data.errors && data.errors.constraint_violation) {
      await Swal.fire({
        text: data.errors.constraint_violation.join(', '),
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
  }
};

const handleCancel = (_) => {
  address.value = "";
  code.value = "";
  open.value = "";
  main.value = 0;

  validationErrors.value = {}

  edit = false;
  toggleModal();
};

const toggleModal = () => {
  emit("toggleModal");
};
</script>
