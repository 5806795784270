<template>
  <h4 class="section-title text-xl font-semibold">{{ title }}</h4>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
