<template>
  <LoadingScreen v-if="loading" />
  <div v-else class="flex flex-wrap">
    <!-- Invoice Add Left starts -->
    <div class="w-full xl:w-10/12 md:w-9/12 px-2 mb-3">
      <div class="card bg-white shadow-md rounded-lg overflow-hidden">
        <div
          class="card-body flex flex-wrap justify-between items-center text-blueGray-700 p-4"
        >
          <!-- Start Header -->
          <div class="w-full">
            <!--<div class="flex justify-end items-center mb-3">
              <h5 class="text-lg font-bold text-gray-500">
                <span>#</span> 000000001
              </h5>
            </div>-->
            <div class="flex flex-wrap">
              <div class="w-full md:w-7/12 px-2">
                <div class="form-group">
                  <label class="form-label"
                    >Establecimiento<span class="text-red-500">*</span></label
                  >
                  <Select
                    v-model="establishmentId"
                    :options="establishments"
                    filter
                    optionLabel="code"
                    optionValue="id"
                    placeholder="Seleccione..."
                    class="w-full my-2"
                  >
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex items-center">
                        {{ getEstablishmentById(slotProps.value).code }} -
                        {{ getEstablishmentById(slotProps.value).address }}
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex items-center">
                        <div>{{ slotProps.option.code }} - {{ slotProps.option.address }}</div>
                      </div>
                    </template>
                  </Select>
                </div>
                <div class="form-group">
                  <label class="form-label"
                    >Cliente<span class="text-red-500">*</span></label
                  >
                  <Select
                    v-model="customerId"
                    :options="customers"
                    filter
                    optionLabel="identification"
                    optionValue="id"
                    placeholder="Seleccione..."
                    class="w-full my-2"
                  >
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex items-center">
                        {{ getCustomerById(slotProps.value).identification }} -
                        {{ getCustomerById(slotProps.value).businessName }}
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex items-center">
                        <div>{{ slotProps.option.identification }} - {{ slotProps.option.businessName }}</div>
                      </div>
                    </template>
                    <template #empty>
                      <div class="flex items-center">
                        <div>Agregar</div>
                      </div>
                    </template>
                  </Select>
                </div>
              </div>
              <div class="w-full md:w-5/12 px-2">
                <div class="form-group">
                  <label class="form-label"
                    >Fecha de emisión<span class="text-red-500">*</span></label
                  >
                  <input
                    type="date"
                    class="w-full my-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    v-model="date"
                    :class="{ 'border-red-500': validationErrors.date }"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Pago hasta</label>
                  <input
                    type="datetime-local"
                    class="w-full my-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    v-model="paymentUntil"
                    :class="{
                      'border-red-500': validationErrors.payment_until,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- End Header -->

          <hr />

          <!-- Start Items -->
          <div class="w-full px-2 mt-3">
            <div class="mt-2" v-for="(item, i) in itemsList" :key="i">
              <CardItem
                :item="item"
                :products="items"
                :index="i"
                :errors="validationErrors"
                @changeItem="updateItem"
                @updateValues="updateValues"
                @deleteItem="handleDelete"
              />
            </div>
            <div class="w-full my-3 flex justify-start">
              <button
                class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleAddItem"
              >
                <i class="fas fa-plus"></i> Agregar
              </button>
            </div>
          </div>
          <!-- End Items -->

          <div class="w-full px-2 mt-5 flex flex-wrap">
            <div class="w-full xl:w-6/12">
              <div class="form-group">
                <label class="form-label"
                  >Método de pago<span class="text-red-500">*</span></label
                >
                <select
                  class="my-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  v-model="paymentMethodId"
                  :class="{ 'border-red-500': validationErrors.paymentMethod }"
                >
                  <option value="0" disabled>Seleccione...</option>
                  <option
                    v-for="paymentMethod in paymentMethods"
                    :value="paymentMethod.id"
                    :key="paymentMethod.id"
                  >
                    {{ paymentMethod.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="form-label">Detalle adicional</label>
                <textarea
                  class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                  :class="{ 'border-red-500': validationErrors.detail }"
                  data-height="50"
                  v-model="detail"
                ></textarea>
              </div>
            </div>
            <div class="w-full xl:w-6/12 flex justify-end">
              <table
                class="text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                  >
                    Subtotal
                  </td>
                  <td class="px-6 py-4">
                    <b>$ {{ subtotal }}</b>
                  </td>
                </tr>
                <tr
                  v-for="tax in taxes"
                  :key="tax.taxId"
                  class="border-b border-gray-200 dark:border-gray-700"
                >
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                  >
                    Subtotal {{ tax.taxInfo.name }}
                  </td>
                  <td class="px-6 py-4">
                    <b>${{ tax.subtotal }}</b>
                  </td>
                </tr>
                <tr
                  v-for="tax in taxes"
                  :key="tax.taxId"
                  class="border-b border-gray-200 dark:border-gray-700"
                >
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                  >
                    {{ tax.taxInfo.taxType.name }} {{ tax.taxInfo.name }}
                  </td>
                  <td class="px-6 py-4">
                    <b>${{ tax.taxValue }}</b>
                  </td>
                </tr>
                <tr
                  v-for="tax in taxes"
                  :key="tax.taxId"
                  class="border-b border-gray-200 dark:border-gray-700"
                >
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                  >
                    Total {{ tax.taxInfo.name }}
                  </td>
                  <td class="px-6 py-4">
                    <b>${{ tax.total }}</b>
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                  >
                    Total
                  </td>
                  <td class="px-6 py-4">
                    <b>$ {{ total }}</b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Invoice Add Left ends -->

    <!-- Invoice Add Right starts -->
    <div class="w-full xl:w-2/12 md:w-3/12 px-2">
      <div class="card bg-white shadow-md rounded-lg overflow-hidden">
        <div
          class="card-body flex justify-center items-center text-blueGray-700 p-4"
        >
          <div class="card-body flex flex-col">
            <button
              class="w-full bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="handleSave(false)"
            >
              Guardar
            </button>
            <button
              class="w-full bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-2"
              type="button"
              @click="handleSave(true)"
            >
              Guardar y Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, defineProps, ref } from "vue";
import { useRouter } from "vue-router";

import CardItem from "./CardItem.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";

import Select from "primevue/select";
import Swal from "sweetalert2";
import moment from "moment";

import "moment-timezone";

import CustomerService from "@/services/customerService";
import DocumentService from "@/services/documentService";
import EstablishmentService from "@/services/establishmentService";
import ItemService from "@/services/itemService";
import PaymentMethodService from "@/services/paymentMethodService";
import TaxService from "@/services/taxService";

import {
  clearSession,
  getBusinessId,
  showAlertProcessDoc,
  showAlertInternalServerErr,
  showAlertSubscriptionError
} from "@/helpers/utils";

const router = useRouter();
const props = defineProps(["id"]);

const service = new DocumentService();

const establishmentService = new EstablishmentService();
const customerService = new CustomerService();
const itemService = new ItemService();
const paymentMethodService = new PaymentMethodService();
const taxService = new TaxService();

const loading = ref(true);

const establishments = ref([]);
const customers = ref([]);
const items = ref([]);
const paymentMethods = ref([]);
const subtotal = ref(0);
const total = ref(0);

const detail = ref("");

// Estados de formulario
const id = ref(props.id);
const establishmentId = ref(0);
const customerId = ref(1);
const paymentMethodId = ref(0);
const date = ref(moment.tz("America/Guayaquil").format("YYYY-MM-DD"));
const paymentUntil = ref(
  moment.tz("America/Guayaquil").format("YYYY-MM-DDTHH:mm")
);
const itemsList = ref([]);
const taxes = ref([]);

const validationErrors = ref([]);

var taxesCat = [];

const handleBeforeUnload = (event) => {
  const message =
    "¿Está seguro que desea salir? Es posible que tenga cambios sin guardar.";
  event.returnValue = message;
  return message;
};

onMounted(() => {
  if (id.value !== null) getDocument();

  window.addEventListener("beforeunload", handleBeforeUnload);
  handleAddItem();
  getData();
});

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

const getData = async () => {
  const reqData = { business_id: getBusinessId() };

  try {
    loading.value = true;

    establishments.value = await establishmentService.get("", reqData);
    customers.value = await customerService.get("", reqData);
    items.value = await itemService.get("", reqData);
    paymentMethods.value = await paymentMethodService.get();
    taxesCat = await taxService.get();
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);
    else if (status === 403) return showAlertSubscriptionError(router, data);
    else if (status === 500) return showAlertInternalServerErr();
  } finally {
    loading.value = false;
  }
};

const getDocument = async () => {
  const reqData = { business_id: getBusinessId() };

  try {
    const doc = await service.get(id.value, reqData);

    establishmentId.value = doc.establishment.id;
    customerId.value = doc.customer.id;
    paymentMethodId.value = doc.paymentMethod.id;
    date.value = getDate(doc.date, "YYYY-MM-DD");
    paymentUntil.value = getDate(doc.paymentUntil, "YYYY-MM-DDTHH:mm");

    itemsList.value = doc.items.map((item) => {
      return {
        itemId: item.item.id,
        discount: item.discount,
        amount: item.amount,
        price: item.price,
        detail: item.detail,
      };
    });

    taxes.value = doc.taxes.map((tax) => {
      return {
        taxId: tax.tax.id,
        subtotal: tax.subtotal,
        taxValue: tax.taxValue,
        total: tax.total,
        taxInfo: tax.tax,
      };
    });

    detail.value = doc.detail;

    updateTotals();
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);
    else if (status === 403) return showAlertSubscriptionError(router, data);
    else if (status === 404) router.push("/admin/facturas");
    else if (status === 500) return showAlertInternalServerErr();
  }
};

const updateItem = (index) => {
  let itemId = itemsList.value[index].itemId;

  itemsList.value.forEach((item, i) => {
    if (index != i && item.itemId == itemId) {
      itemsList.value.splice(i, 1);
      itemId = -1;

      Swal.fire({
        text: "Ya se agregó con anterioridad",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  });

  if (itemId == -1) return;

  const item = items.value.find((item) => item.id === itemId);
  if (item) {
    itemsList.value[index].price = item.unitPrice;
    itemsList.value[index].detail = item.name;
  }

  const errItem = "items." + index + ".item_id";
  const errAmount = "items." + index + ".amount";
  const errDetail = "items." + index + ".detail";
  const errDiscount = "items." + index + ".discount";
  const errPrice = "items." + index + ".price";

  if (validationErrors.hasOwnProperty(errItem))
    delete validationErrors[errItem];
  if (validationErrors.hasOwnProperty(errAmount))
    delete validationErrors[errAmount];
  if (validationErrors.hasOwnProperty(errDetail))
    delete validationErrors[errDetail];
  if (validationErrors.hasOwnProperty(errDiscount))
    delete validationErrors[errDiscount];
  if (validationErrors.hasOwnProperty(errPrice))
    delete validationErrors[errPrice];

  updateValues();
};

const updateValues = async () => {
  let taxesAux = [];

  for (const element of itemsList.value) {
    try {
      let index = -1;

      const reqData = { business_id: getBusinessId() };
      const item = await itemService.get(element.itemId, reqData);
      const tax = taxesCat.find((taxAux) => taxAux.id == item.taxId);

      taxesAux.forEach((taxAux, i) => {
        if (taxAux.taxId == tax.id) index = i;
      });

      let subtotal = element.price * element.amount;
      subtotal = Number(subtotal.toFixed(2));
      subtotal -= (subtotal * element.discount) / 100;
      subtotal = Number(subtotal.toFixed(2));

      let taxValue = subtotal * tax.factor;
      taxValue = Number(taxValue.toFixed(2));

      let total = subtotal + taxValue;
      total = Number(total.toFixed(2));

      if (index == -1) {
        taxesAux.push({
          taxId: tax.id,
          subtotal: subtotal,
          taxValue: taxValue,
          total: total,
          taxInfo: tax,
        });
      } else {
        taxesAux[index].subtotal += subtotal;
        taxesAux[index].taxValue += taxValue;
        taxesAux[index].total += total;
      }
    } catch (error) {
      const { data, status } = error;

      if (status === 401) return clearSession(router, true);
      else if (status === 403) return showAlertSubscriptionError(router, data);
      else if (status === 500) return showAlertInternalServerErr();
    }
  }

  taxes.value = taxesAux;
  updateTotals();
};

const handleAddItem = () => {
  itemsList.value.push({
    itemId: "",
    discount: 0,
    amount: 1,
    price: "",
    detail: "",
  });
};

const handleSave = async (emit) => {
  if (!isValid()) return;

  const reqData = {
    business_id: getBusinessId(),
    customer_id: customerId.value,
    document_state_id: 1,
    document_type_id: 1,
    emission_type_id: 1,
    establishment_id: establishmentId.value,
    payment_method_id: paymentMethodId.value,
    date: moment(date.value).format("YYYY-MM-DD"),
    payment_until: moment(paymentUntil.value).format("YYYY-MM-DD HH:mm:ss"),
    detail: detail.value,
    subtotal: subtotal.value,
    total: total.value,

    items: itemsList.value.map((item) => {
      return {
        item_id: item.itemId !== "0" ? item.itemId : "",
        discount: item.itemId !== "0" ? item.discount : "",
        amount: item.itemId !== "0" ? item.amount : "",
        price: item.itemId !== "0" ? item.price : "",
        detail: item.itemId !== "0" ? item.detail : "",
      };
    }),

    taxes: taxes.value.map((tax) => {
      return {
        tax_id: tax.taxId,
        subtotal: tax.subtotal,
        tax_value: tax.taxValue,
        total: tax.total,
      };
    }),

    emit: emit,
  };

  showAlertProcessDoc();

  try {
    const res =
      id.value !== null
        ? await service.put(id.value, reqData)
        : await service.post(reqData);
    let title = null;
    let mgs = null;
    let footer = null;

    if (emit) {
      title = "Factura Autorizada";
      mgs = "";
      footer =
        "<p><strong>Ambiente: </strong>" +
        res.sri.ambiente +
        "</p>" +
        "<p><strong>Clave de Acceso: </strong>" +
        res.sri.numeroAutorizacion +
        "</p>";
    } else {
      title = null;
      mgs = "Factura guardada exitosamente";
    }

    Swal.close();
    await Swal.fire({
      title: title,
      text: mgs,
      footer: footer,
      icon: "success",
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });

    router.push("/admin/facturas");
  } catch (error) {
    const { data, status } = error;

    Swal.close();

    if (status === 401) return clearSession(router, true);
    else if (status === 403) return showAlertSubscriptionError(router, data);
    else if (status === 500) return showAlertInternalServerErr();

    if (status == 400 && data.errors && data.errors.validation) {
      await Swal.fire({
        title: "Campos incorrectos y/o faltantes",
        text: "Verifique los campos de la factura",
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      const { errors } = data;

      let auxErrors = {};
      errors.validation.forEach((validationError) => {
        auxErrors[validationError.field] = validationError.message;
      });

      validationErrors.value = auxErrors;
    }

    if (status == 400 && data.errors && data.errors.constraint_violation)
      await Swal.fire({
        text: data.errors.constraint_violation.join(", "),
        icon: "error",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

    if (status == 400 && data.errors.process) {
      const processCode = data.errors.process.code;

      let title = null;
      let mgs = "";
      let footer = null;

      if (typeof data.errors.sri !== "string" || processCode == 1) {
        switch (processCode) {
          case 1:
            title = "Error al firmar el comprobante";
            mgs = data.errors.sri;
            break;
          case 2:
            title = data.errors.sri.estado;
            mgs =
              data.errors.sri.comprobantes.comprobante.mensajes.mensaje.mensaje;
            break;
          case 3:
            title = data.errors.sri.autorizaciones.autorizacion.estado;
            mgs =
              data.errors.sri.autorizaciones.autorizacion.mensajes.mensaje
                .mensaje;
            break;
        }

        footer = processCode != 1 ? "Esta es una respuesta del SRI" : null;
      } else mgs = "Error interno del SRI";

      await Swal.fire({
        title: title,
        text: mgs,
        footer: footer,
        icon: "error",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      router.push("/admin/facturas");
    }
  }
};

const handleDelete = (index) => {
  itemsList.value.splice(index, 1);

  //if (itemsList.value.length == 0) handleAddItem();

  updateValues();
};

const isValid = (_) => {
  return true;
};

const getDate = (date, format) => {
  const dateLoaded = moment.unix(date.timestamp).utcOffset(date.offset / 60);
  return dateLoaded.format(format);
};

const updateTotals = (_) => {
  subtotal.value = 0;
  total.value = 0;

  taxes.value.forEach((tax) => {
    subtotal.value += tax.subtotal;
    subtotal.value = Number(subtotal.value.toFixed(2));

    total.value += tax.total;
    total.value = Number(total.value.toFixed(2));
  });
};

function getEstablishmentById(id) {
  return establishments.value.find((est) => est.id === id) || {};
}

function getCustomerById(id) {
  return customers.value.find((cus) => cus.id === id) || {};
}
</script>
