<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Iniciar Sesión
              </h6>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="handleSubmit">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Nombre de Usuario
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Ingrese su usuario"
                  v-model="username"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Contraseña
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Ingrese su contraseña"
                  v-model="password"
                />
              </div>
              <!--<div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Mantener sesión abierta
                  </span>
                </label>
              </div>-->

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-full text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Registrarse</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';

import Swal from 'sweetalert2'

import { login, profile } from "@/services/authService.js";

import { clearSession } from '@/helpers/utils'

const router = useRouter();

const username = ref("");
const password = ref("");
//const remember = ref(false);

const handleSubmit = async () => {
  const credentials = {
    username: username.value,
    password: password.value,
  };

  try {
    const resLogin = await login(credentials);
    localStorage.setItem('token', resLogin.token);

    const resProfile = await profile();
    const user = JSON.stringify(resProfile);
    localStorage.setItem('user', user);

    Swal.fire({
      title: 'Inicio se sesión exitoso',
      icon: 'success',
      confirmButtonText: 'Aceptar'
    });

    router.push('/admin/panel');
  } catch (error) {
    const { data, status } = error;

    if (status === 401) 
      Swal.fire({
        title: '¡Error!',
        text: 'Credenciales invalidas',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
  }
};
</script>
