<template>
  <div
    class="fixed inset-0 flex items-center justify-center bg-white"
  >
    <div class="loader">
    </div>
  </div>
</template>

<script>
export default { };
</script>

<style scoped>
.loader {
  border: 8px solid #ccc;
  border-top: 8px solid #7c3aed;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
