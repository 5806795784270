<template>
  <div class="card bg-white shadow-md rounded-lg overflow-hidden mb-3">
    <div class="card-header flex justify-between items-center text-white p-3">
      <h4 class="text-lg font-semibold text-blueGray-700">Secuencias</h4>
      <button
        class="bg-purple-500 hover:bg-purple-700 text-white px-2 py-1 rounded"
        :class="{ 'bg-purple-700': saving }"
        :disabled="saving"
        @click="handleSave"
      >
        Guardar
      </button>
    </div>
    <div class="card-body p-4">
      <div v-for="sequence in sequences" :key="sequence.id">
        <div v-if="sequence.shortName == 'Factura'" class="flex items-center my-2">
          <label class="w-2/3 block text-gray-700 text-sm font-bold">{{
            sequence.shortName
          }}</label>
          <input
            type="number"
            class="w-1/3 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            min="0"
            v-model="sequence.value"
          />
        </div>
        <!--<hr />-->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from "vue";
import { useRouter } from "vue-router";

import SequenceService from "@/services/sequenceService";
import { showAlertSuccessChanges } from "@/helpers/utils";

const router = useRouter();

const props = defineProps({
  sequences: Array
})

const saving = ref(false);

const { sequences } = props;
const service = new SequenceService()

onMounted(() => {
  //
});

const handleSave = async _ => {
  saving.value = true;

  const sequence = sequences.find((sequence) => sequence.shortName === 'Factura');
  
  try {
    await service.put(sequence.id, { value: sequence.value });
  
    showAlertSuccessChanges();
  } catch (error) {
    const { data, status } = error;

    if (status === 401) clearSession(router, true);
  }

  saving.value = false;
}
</script>

<style scoped></style>
