import axios from 'axios';
import { payphoneBaseUri, payphoneToken } from '@/helpers/constants.js';

export const verifyPay = async values => {
    const url = payphoneBaseUri + '/Confirm'

    try {
        const { data } = await axios.post(url, values, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${payphoneToken}`,
            }
        });
        
        return data
    } catch (error) {
        if (!error.response)
            throw error

        const { data, status } = error.response
        throw { data, status }
    }
}
