import axios from 'axios';
import { backendBaseUri } from '@/helpers/constants.js'
import { getToken } from '@/helpers/utils.js'

export const login = async credentials => {
    const url = backendBaseUri + '/login'

    try {
        const { data } = await axios.post(url, credentials, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        
        return data
    } catch (error) {
        if (!error.response)
            throw error

        const { data, status } = error.response
        throw { data, status }
    }
}

export const register = async values => {
    const url = backendBaseUri + '/register'

    try {
        const { data } = await axios.post(url, values, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        return data
    } catch (error) {
        if (!error.response)
            throw error

        const { data, status } = error.response
        throw { data, status }
    }
}

export const logout = async _ => {
    const url = backendBaseUri + '/logout'

    try {
        const { data } = await axios.post(url, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        return data
    } catch (error) {
        if (!error.response)
            throw error

        const { data, status } = error.response
        throw { data, status }
    }
}

export const verify = _ => {
    //
}

export const profile = async _ => {
    const url = backendBaseUri + '/profile'

    try {
        const { data } = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        return data
    } catch (error) {
        if (!error.response)
            throw error

        const { data, status } = error.response
        throw { data, status }
    }
}

export const profileUpdate = async values => {
    const url = backendBaseUri + '/profile'

    try {
        const { data } = await axios.put(url, values, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        return data
    } catch (error) {
        if (!error.response)
            throw error

        const { data, status } = error.response
        throw { data, status }
    }
}
