<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <SectionHeader title="Nueva Factura" />
      </div>
      <div class="w-full mb-4 px-4">
        <CardNewDoc :id="id"/>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/Cards/SectionHeader.vue";
import CardNewDoc from "@/components/Cards/CardNewDoc.vue";

export default {
  data() {
    return {
      id: null
    };
  },
  created() {
    this.checkRoute();
  },
  components: {
    SectionHeader,
    CardNewDoc
  },
  watch: {
    '$route'() {
      this.checkRoute();
    }
  },
  methods: {
    checkRoute() {
      this.id = (this.$route.params.id)
        ? this.$route.params.id
        : null
      ;
    }
  }
};
</script>
