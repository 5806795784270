<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <SectionHeader title="Clientes" />
      </div>
      <div class="w-full mb-4 px-4">
        <CardCustomers />
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/Cards/SectionHeader.vue";
import CardCustomers from "../../components/Cards/CardCustomers.vue";

export default {
  components: {
    SectionHeader,
    CardCustomers,
  },
};
</script>
