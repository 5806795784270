<template>
    <div>
      <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
          <SectionHeader title="Facturas" />
        </div>
        <div class="w-full mb-4 px-4">
          <CardDocuments />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SectionHeader from "@/components/Cards/SectionHeader.vue";
  import CardDocuments from "../../components/Cards/CardDocuments.vue";
  
  export default {
    components: {
      SectionHeader,
      CardDocuments
    },
  };
  </script>
  