<template>
  <LoadingScreen v-if="loading" />
  <div
    v-else
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="rounded-t mb-0 px-4 py-2 border-0">
      <!--<div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <button
            class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            Nueva
          </button>
        </div>
      </div>-->
    </div>
    <div class="block w-full overflow-x-auto">
      <DataTable
        v-model:filters="filters"
        :value="documents"
        paginator
        :rows="10"
        dataKey="id"
        filterDisplay="row"
        :loading="loading"
        :globalFilterFields="[
          'number',
          'customer',
          'date',
          'total',
          'documentState',
          'emailSent',
        ]"
        class="custom-header"
      >
        <template #header>
          <div class="flex justify-end">
            <IconField>
                <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
            </IconField>
          </div>
        </template>
        <template #empty> No se encontraron facturas. </template>
        <template #loading> Cargando facturas. Espere por favor. </template>

        <Column
          field="number"
          header="#"
          :filter="true"
          filterPlaceholder="Search by number"
        >
          <template #body="{ data }">
            <span class="text-sm">{{ data.number }}</span>
          </template>
        </Column>
        <Column
          field="customer"
          header="Cliente"
          :filter="true"
        >
          <template #body="{ data }">
            <span class="text-sm">{{ data.customer }}</span>
          </template>
        </Column>
        <Column
          field="date"
          header="Fecha"
          :filter="true"
        >
          <template #body="{ data }">
            <span class="text-sm">{{ getDate(data.date) }}</span>
          </template>
        </Column>
        <Column
          field="total"
          header="Total"
          :filter="true"
          >
          <template #body="{ data }">
            <span class="text-sm">${{ data.total }}</span>
          </template>
        </Column>
        <Column
          field="documentState"
          header="Estado"
          :filter="true"
        >
          <template #body="slotProps">
            <i
              class="fas fa-circle mr-2 text-sm"
              :class="{
                'text-lime-500': slotProps.data.documentState === 'Autorizado',
                'text-red-500':
                  slotProps.data.documentState === 'No autorizado',
                'text-purple-500':
                  slotProps.data.documentState === 'En procesamiento' &&
                  slotProps.data.number === null,
                'text-yellow-400':
                  slotProps.data.documentState === 'En procesamiento' &&
                  slotProps.data.number !== null,
              }"
            ></i>
            <span class="text-sm">
              {{
                slotProps.data.documentState === "En procesamiento" &&
                slotProps.data.number === null
                  ? "Guardado"
                  : slotProps.data.documentState
              }}
            </span>
          </template>
        </Column>
        <Column
          field="emailSent"
          header="Email"
          :filter="true"
        >
          <template #body="slotProps">
            <i
              class="fas fa-circle mr-2"
              :class="{
                'text-lime-500': slotProps.data.emailSent,
                'text-red-500': !slotProps.data.emailSent,
              }"
            ></i>
            <span class="text-sm">
              {{
                slotProps.data.emailSent ? "Email enviado" : "Email no enviado"
              }}
            </span>
          </template>
        </Column>
        <Column header="Acciones">
          <template #body="slotProps">
            <div v-if="slotProps.data.documentState === 'Autorizado'">
              <button
                class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleDownloadFile(slotProps.data.id, 'pdf')"
              >
                <i class="fa-solid fa-file-pdf"></i>
              </button>
              <button
                class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleDownloadFile(slotProps.data.id, 'xml')"
              >
                <i class="fa-solid fa-file"></i>
              </button>
              <button
                class="bg-lime-500 text-white active:bg-lime-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleResendEmail(slotProps.data.id)"
              >
                <i class="fa-solid fa-envelope"></i>
              </button>
            </div>
            <div
              v-if="
                slotProps.data.documentState === 'En procesamiento' &&
                slotProps.data.number === null
              "
            >
              <button
                class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleEdit(slotProps.data.id)"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleDelete(slotProps.data.id)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <div
              v-if="
                slotProps.data.documentState === 'En procesamiento' &&
                slotProps.data.number !== null
              "
            >
              <button
                class="bg-yellow-400 text-white active:bg-yellow-500 font-bold uppercase text-xs px-3 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleVerifyDoc(slotProps.data.id)"
              >
                <i class="fa-solid fa-rotate-right"></i>
              </button>
            </div>
            <div v-if="slotProps.data.documentState === 'No autorizado'">
              <span class="text-sm">Sin acciones...</span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";
import "moment-timezone";

import DocumentService from "@/services/documentService";
import EmailService from "@/services/emailService";
import FileService from "@/services/fileService";

import LoadingScreen from "../LoadingScreen.vue";

import { clearSession, getBusinessId } from "@/helpers/utils";
import {
  showAlertProcessDoc,
  showAlertInternalServerErr,
  showAlertSubscriptionError,
} from "@/helpers/utils";

import Swal from "sweetalert2";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "@primevue/core/api";
import InputText from "primevue/inputtext";

const router = useRouter();

const service = new DocumentService();
const emailService = new EmailService();
const fileService = new FileService();

const documents = ref([]);
const loading = ref(true);

const reqData = { business_id: getBusinessId() };

// Data Table
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  number: { value: null, matchMode: FilterMatchMode.CONTAINS },
  customer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  date: { value: null, matchMode: FilterMatchMode.CONTAINS },
  total: { value: null, matchMode: FilterMatchMode.EQUALS },
  documentState: { value: null, matchMode: FilterMatchMode.EQUALS },
  emailSent: { value: null, matchMode: FilterMatchMode.EQUALS },
});

onMounted(() => {
  getData();
});

const getData = async () => {
  loading.value = true;

  try {
    documents.value = await service.get("", reqData);
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);
    else if (status === 403) return showAlertSubscriptionError(router, data);
    else if (status === 500) return showAlertInternalServerErr(router, data);
  } finally {
    loading.value = false;
  }
};

const getDate = (date) => {
  const dateLoaded = moment.unix(date.timestamp).utcOffset(date.offset / 60);
  return dateLoaded.format("DD/MMM/YYYY");
};

const handleVerifyDoc = async (id) => {
  showAlertProcessDoc();

  try {
    const res = await service.put(id, { verify: true, emit: false });

    Swal.close();
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);
    else if (status === 403) return showAlertSubscriptionError(router, data);
    else if (status === 500) return showAlertInternalServerErr();

    let title = null;
    let mgs = data.errors ? "" : data.message;
    let footer =
      data.errors && data.errors.sri ? "Esta es una respuesta del SRI" : null;

    mgs = data.sign ?? mgs;

    if (data.errors) {
      const { errors } = data;

      if (errors.sri.reception) {
        title =
          typeof errors.sri.reception !== "string"
            ? errors.sri.reception.estado
            : null;

        mgs =
          typeof errors.sri.reception !== "string"
            ? errors.sri.reception.comprobantes.comprobante.mensajes.mensaje
                .mensaje
            : "Error interno de el SRI";
      }

      if (errors.sri.authorization) {
        title =
          typeof errors.sri.authorization !== "string"
            ? data.errors.sri.authorization.autorizaciones.autorizacion.estado
            : null;

        mgs =
          typeof errors.sri.authorization !== "string"
            ? data.errors.sri.authorization.autorizaciones.autorizacion.mensajes
                .mensaje.mensaje
            : "Error interno de el SRI";
      }
    }

    Swal.close();

    Swal.fire({
      title: title,
      text: mgs,
      icon: "warning",
      footer: footer,
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  }

  getData();
};

const handleResendEmail = async (id) => {
  const confirmResult = await Swal.fire({
    title: "¿Desea enviar nuevamente la factura por correo electrónico?",
    showCancelButton: true,
    confirmButtonText: "Enviar",
    cancelButtonText: "Cancelar",
  });

  if (confirmResult.isConfirmed) {
    showAlertProcessDoc();

    try {
      const { message } = await emailService.get(id);

      Swal.close();

      await Swal.fire({
        text: message,
        icon: "success",
      });
    } catch (err) {
      const { message, status } = err.data;

      if (status === 401) return clearSession(router, true);
      else if (status === 403) return showAlertSubscriptionError(router, data);
      else if (status === 500) return showAlertInternalServerErr();

      Swal.close();
      await Swal.fire({
        text: message,
        icon: "error",
      });
    }

    await getData();
  }
};

const handleEdit = async (id) => {
  router.push(`/admin/facturas/editar/${id}`);
};

const handleDelete = async (id) => {
  const confirmResult = await Swal.fire({
    title: "¿Desea confirmar la eliminación?",
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
  });

  if (confirmResult.isConfirmed) {
    showAlertProcessDoc();

    try {
      await service.delete(id);
      getData();

      Swal.close();

      Swal.fire({
        title: "Registro eliminado exitosamente",
        icon: "success",
      });
    } catch (error) {
      const { data, status } = error;
      if (status === 401) return clearSession(router, true);

      const mgs =
        status == 404
          ? data.message
          : data.errors.constraint_violation.join(", ");
      Swal.close();

      Swal.fire({
        text: mgs,
        icon: "error",
      });
    }
  }
};

const handleDownloadFile = async (id, file_type) => {
  showAlertProcessDoc();

  try {
    const doc = await service.get(id, reqData);
    const res = await fileService.get(id, { file_type });

    const url = window.URL.createObjectURL(new Blob([res.data]));

    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", doc.accessKey + "." + file_type);

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);
    else if (status === 403) return showAlertSubscriptionError(router, data);
    else if (status === 500) return showAlertInternalServerErr();
  }

  Swal.close();
};
</script>
