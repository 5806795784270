<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Mi cuenta</h6>
        <button
          class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="handleSave"
        >
          Actualizar
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Información del usuario
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Nombre de usuario
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :class="{ 'border-red-500': validationErrors.username }"
                v-model="username"
              />
              <div
                v-if="validationErrors.username"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.username }}
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Correo Electrónico
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :class="{ 'border-red-500': validationErrors.email }"
                v-model="email"
              />
              <div
                v-if="validationErrors.email"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.email }}
              </div>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Número de identificación
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="document"
                :class="{ 'border-red-500': validationErrors.document }"
              />
              <div
                v-if="validationErrors.document"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.document }}
              </div>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Nombre
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="name"
                :class="{ 'border-red-500': validationErrors.name }"
              />
              <div
                v-if="validationErrors.name"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.name }}
              </div>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Apellido
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="lastname"
                :class="{ 'border-red-500': validationErrors.lastname }"
              />
              <div
                v-if="validationErrors.lastname"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.lastname }}
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Seguridad
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Nueva contraseña
              </label>
              <input
                type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="password"
                :class="{ 'border-red-500': validationErrors.password }"
              />
              <div
                v-if="validationErrors.password"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.password }}
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Confirmar Contraseña
              </label>
              <input
                type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="passwordConfirm"
                :class="{
                  'border-red-500': validationErrors.password_confirmation,
                }"
              />
              <div
                v-if="validationErrors.password_confirmation"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.password_confirmation }}
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Suscripción
        </h6>
        <div class="flex flex-wrap">
          <div v-if="isActive" class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <p class="uppercase text-blueGray-600 text-xs mb-2">
                <b>Tipo: </b><span>{{ subscriptionType }}</span>
              </p>
              <p class="uppercase text-blueGray-600 text-xs mb-2">
                <b>Desde: </b><span>{{ getDate(startDate) }}</span>
              </p>
              <p class="uppercase text-blueGray-600 text-xs mb-2">
                <b>Caducidad: </b
                ><span>{{ endDate != null ? getDate(endDate) : "-" }}</span>
              </p>
              <p class="uppercase text-blueGray-600 text-xs mb-2">
                <b>Proxima renovación: </b
                ><span>{{ endDate != null ? getDate(endDate) : "-" }}</span>
              </p>
            </div>
          </div>
          <div v-else class="w-full lg:w-12/12 px-4">
            <p
              class="uppercase text-blueGray-600 text-xs font-bold text-red-500 mb-2"
            >
              No se ha suscrito
            </p>
            <CardSubscribe />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";
import "moment-timezone";

import { profile, profileUpdate } from "@/services/authService";
import { clearSession, getUserData, generateRandomString } from "@/helpers/utils";

import CardSubscribe from "@/components/Cards/CardSubscribe.vue";

import Swal from "sweetalert2";

const router = useRouter();

const validationErrors = ref([]);

const username = ref("");
const email = ref("");
const document = ref("");
const name = ref("");
const lastname = ref("");
const password = ref("");
const passwordConfirm = ref("");
const subscriptionType = ref("");
const startDate = ref("");
const endDate = ref("");
const hasSubscription = ref(false);
const isActive = ref(true);

onMounted(() => {
  getData();
});

const getData = async () => {
  try {
    const res = await profile();

    username.value = res.username;
    email.value = res.email;
    document.value = res.document;
    name.value = res.name;
    lastname.value = res.lastname;
    subscriptionType.value = res.subscriptionType;
    startDate.value = res.startDate;
    endDate.value = res.endDate;
    hasSubscription.value = res.hasSubscription;
    isActive.value = res.isActive;
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);
  }
};

const getDate = (date) => {
  if (date && date.timestamp && date.offset) {
    const dateLoaded = moment.unix(date.timestamp).utcOffset(date.offset / 60);
    return dateLoaded.format("DD/MMM/YYYY");
  } else {
    return "-";
  }
};

const handleSave = async () => {
  const reqData = {
    document: document.value,
    name: name.value,
    lastname: lastname.value,
    username: username.value,
    email: email.value,
  };

  if (password.value !== "") {
    reqData.password = password.value;
    reqData.password_confirmation = passwordConfirm.value;
  }

  try {
    const resProfile = await profileUpdate(reqData);

    const user = JSON.stringify(resProfile);
    localStorage.setItem("user", user);

    await Swal.fire({
      text: "Cambios guardados exitosamente",
      icon: "success",
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });

    validationErrors.value = [];
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);

    if (status == 400 && data.errors && data.errors.validation) {
      await Swal.fire({
        title: "Campos incorrectos y/o faltantes",
        text: "Verifique los campos",
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      const { errors } = data;

      let auxErrors = {};
      errors.validation.forEach((validationError) => {
        auxErrors[validationError.field] = validationError.message;
      });

      validationErrors.value = auxErrors;
    }
  }
};
</script>
