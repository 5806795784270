<template>
  <div class="card bg-white shadow-md rounded-lg overflow-hidden">
    <div
      class="card-header flex justify-between items-center text-blueGray-700 p-4"
    >
      <h4 class="text-lg font-semibold">Datos de facturación</h4>
      <button
        class="bg-purple-500 hover:bg-purple-700 text-white px-2 py-1 rounded"
        :class="{ 'bg-purple-700': saving }"
        @click="handleSave"
        :disabled="saving"
      >
        Guardar
      </button>
    </div>
    <div class="card-body p-3">
      <div class="w-full mb-4 px-2 flex flex-col lg:flex-row">
        <div class="form-group lg:w-1/2 px-2">
          <label class="mb-2">Logotipo</label>
          <p v-if="business.logo" class="text-xs">
            Ya se ha cargado un logotipo
          </p>
          <p v-else class="text-xs text-red-500">
            No se ha cargado un logotipo
          </p>
          <div class="w-full">
            <input
              class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white mt-2"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              accept=".jpg, .jpeg, .png"
              @change="handleUpdateLogo"
            />
            <p class="mt-1 text-sm text-gray-500" id="file_input_help">
              PNG, JPG, JPEG.
            </p>
          </div>
        </div>
        <div class="form-group lg:w-1/2 px-2">
          <label class="mb-2">Firma Electrónica</label>
          <p v-if="business.sign" class="text-xs">Ya se ha cargado una firma</p>
          <p v-else class="text-xs text-red-500">No se ha cargado una firma</p>
          <div class="w-full">
            <input
              class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white mt-2"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              accept=".p12"
              @change="handleUpdateSign"
            />
            <p class="mt-1 text-sm text-gray-500" id="file_input_help">
              P12 (Max 1MB).
            </p>
          </div>
        </div>
      </div>
      <div class="w-full mt-4 px-2 flex flex-col lg:flex-row">
        <div class="form-group lg:w-1/3 px-2">
          <label class="mb-2">RUC<span class="text-red-700"> *</span></label>
          <input
            type="text"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.ruc }"
            v-model="business.ruc"
          />
        </div>
        <div class="form-group lg:w-1/3 px-2">
          <label class="mb-2"
            >Razón Social<span class="text-red-700"> *</span></label
          >
          <input
            type="text"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.business_name }"
            v-model="business.businessName"
          />
        </div>
        <div class="form-group lg:w-1/3 px-2">
          <label class="mb-2"
            >Nombre Comercial<span class="text-red-700"> *</span></label
          >
          <input
            type="text"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.tradename }"
            v-model="business.tradename"
          />
        </div>
      </div>
      <div class="w-full mt-4 px-2 flex flex-col lg:flex-row">
        <div class="form-group lg:w-1/3 px-2">
          <label class="mb-2"
            >Código del punto de emisión<span class="text-red-700">
              *</span
            ></label
          >
          <input
            type="text"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.issuance_point_code }"
            v-model="business.issuancePointCode"
          />
        </div>
        <div class="form-group lg:w-1/3 px-2">
          <label class="mb-2">Contribuyente especial</label>
          <input
            type="text"
            placeholder="Número de resolución"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.special_taxpayer }"
            v-model="business.specialTaxpayer"
          />
        </div>
        <div class="form-group lg:w-1/3 px-2">
          <label class="mb-2">Agente de retención</label>
          <input
            type="text"
            placeholder="Número de resolución"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.retention_agent }"
            v-model="business.retentionAgent"
          />
        </div>
      </div>
      <div class="w-full mt-4 px-2 flex flex-col lg:flex-row">
        <div class="form-group lg:w-1/2 px-2">
          <label class="mb-2">Email<span class="text-red-700"> *</span></label>
          <input
            type="text"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.email }"
            v-model="business.email"
          />
        </div>
        <div class="form-group lg:w-1/2 px-2">
          <label class="mb-2"
            >Número de teléfono<span class="text-red-700"> *</span></label
          >
          <input
            type="text"
            class="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
            :class="{ 'border-red-500': validationErrors.phone }"
            v-model="business.phone"
          />
        </div>
      </div>
      <div class="w-full mt-4 px-2 flex flex-col lg:flex-row">
        <div class="w-1/2 p-2">
          <div class="flex items-center mb-4">
            <input
              id="default-checkbox"
              type="checkbox"
              class="w-4 h-4 text-purple-600 rounded focus:ring-blue-500 focus:ring-2"
              v-model="business.accounting"
            />
            <label for="default-checkbox" class="ms-2 text-sm font-medium"
              >Obligado a llevar contabilidad</label
            >
          </div>
          <div class="flex items-center">
            <input
              id="checked-checkbox"
              type="checkbox"
              class="w-4 h-4 text-purple-600 rounded focus:ring-blue-500 focus:ring-2"
              v-model="business.rimpe"
            />
            <label for="checked-checkbox" class="ms-2 text-sm font-medium"
              >Contribuyente RIMPE</label
            >
          </div>
        </div>
        <div class="w-1/2 p-2">
          <div class="flex items-center mb-4">
            <input
              id="env-1"
              type="radio"
              value="2"
              name="env"
              class="w-4 h-4 text-purple-600"
              v-model="environment_id"
            />
            <label for="env-1" class="ms-2 text-sm font-medium"
              >Producción</label
            >
          </div>
          <div class="flex items-center">
            <input
              id="env-2"
              type="radio"
              value="1"
              name="env"
              class="w-4 h-4 text-purple-600"
              v-model="environment_id"
            />
            <label for="env-2" class="ms-2 text-sm font-medium"
              >Pruebas</label
            >
          </div>
        </div>
      </div>

      <hr />

      <div class="w-full mt-4 px-2 flex flex-col lg:flex-row justify-between">
        <div class="form-group lg:w-1/3 px-2">
          <label class="mb-2">Contraseña de la firma electrónica</label>
          <input
            type="password"
            class="px-3 py-2 text-blueGray-600 relative bg-white rounded text-sm border border-blueGray-300 outline-none w-full"
            v-model="signPass"
          />
        </div>

        <div class="flex flex-col px-2 mt-2">
          <p v-if="business.signVerified" class="text-xs text-lime-700">
            Firma verificada
          </p>
          <p v-else class="text-xs text-red-500">
            No ha verificado su firma
          </p>
          <button
            v-if="!business.signVerified"
            class="bg-purple-500 hover:bg-purple-700 text-white px-2 py-1 rounded mt-1"
            :class="{ 'bg-purple-700': saving }"
            @click="handleVerifySign"
          >
            Verificar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from "vue";
import { useRouter } from "vue-router";

import BusinessService from "@/services/businessService";
import VerifyService from "@/services/verifyService";

import { clearSession, showAlertSuccessChanges } from "@/helpers/utils";
import Swal from "sweetalert2";

const router = useRouter();

const props = defineProps({
  business: Object,
});

const { business } = props;

const verifyService = new VerifyService();
const service = new BusinessService();

const validationErrors = ref([]);
const environment_id = ref(1);
const signPass = ref("");
const saving = ref(false);

onMounted(() => {
  environment_id.value = business.environment == "Pruebas" ? 1 : 2;
});

const handleSave = async () => {
  saving.value = true;

  const reqData = {
    environment_id: environment_id.value,
    ruc: business.ruc,
    business_name: business.businessName,
    tradename: business.tradename,
    email: business.email,
    phone: business.phone,
    issuance_point_code: business.issuancePointCode,
    special_taxpayer: business.specialTaxpayer,
    retention_agent: business.retentionAgent,
    accounting: business.accounting,
    rimpe: business.rimpe,
    sign_pass: signPass.value,
  };

  try {
    await service.put(business.id, reqData);

    showAlertSuccessChanges()

    if (signPass.value !== "")
      business.signVerified = false;
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);

    if (status == 400 && data.errors && data.errors.validation) {
      await Swal.fire({
        title: "Campos incorrectos y/o faltantes",
        text: "Verifique los campos de la factura",
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      const { errors } = data;

      let auxErrors = {};
      errors.validation.forEach((validationError) => {
        auxErrors[validationError.field] = validationError.message;
      });

      validationErrors.value = auxErrors;
    }
  }

  signPass.value = "";

  saving.value = false;
};

const handleUpdateLogo = async (event) => {
  saving.value = true;

  const file = event.target.files[0];
  if (file) {
    const logo = file;

    const formData = new FormData();
    formData.append("logo", logo);

    try {
      await service.postFiles(business.id, formData);

      Swal.fire({
        title: "Logo actualizado exitosamente",
        icon: "success",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      business.logo = true;
    } catch (error) {
      const { data, status } = error;

      if (status === 401) return clearSession(router, true);

      Swal.fire({
        text: data.message,
        icon: "error",
      });
    }
  }

  saving.value = false;
};

const handleUpdateSign = async (event) => {
  saving.value = true;

  const file = event.target.files[0];
  if (file) {
    const sign = file;

    const formData = new FormData();
    formData.append("sign", sign);

    try {
      await service.postFiles(business.id, formData);

      Swal.fire({
        title: "Firma actualizada exitosamente",
        icon: "success",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      business.signVerified = false;
      business.sign = true;
    } catch (error) {
      const { data, status } = error;

      if (status === 401) return clearSession(router, true);

      Swal.fire({
        text: data.message,
        icon: "error",
      });
    }
  }

  saving.value = false;
};

const handleVerifySign = async () => {
  try {
    const res = await verifyService.get(business.id);
    
    Swal.fire({
      html: '<p><b>Emisor: </b>'+ res.is_valid_issuer +'</p>' + 
        '<p><b>Contraseña: </b>'+ res.is_valid_pass +'</p>',
      icon: "success",
    });

    business.signVerified = true;
  } catch (error) {
    const { data, status } = error;

    if (status === 401) return clearSession(router, true);

    if (data.constraint_violation)
      Swal.fire({
        text: data.constraint_violation.join(', '),
        icon: "error",
      });
    else 
      Swal.fire({
        html: '<p><b>Emisor: </b>'+ data.is_valid_issuer +'</p>' + 
          '<p><b>Contraseña: </b>'+ data.is_valid_pass +'</p>',
        icon: "waring",
      });
  }
}
</script>

<style scoped></style>
