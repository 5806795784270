<template>
  <div id="pp-button"></div>
</template>

<script setup>
import { onMounted } from "vue";

import moment from "moment";
import "moment-timezone";

import { generateRandomString } from "@/helpers/utils";

onMounted(() => {
  const uniqueId =
    moment().format("YYYYMMDDHHmmssSSS") + "-" + generateRandomString(8);

  payphone
    .Button({
      token:
        "rN2SPKjYNsrgARcwslPKih0LQ5DaWOSFjWlCStTaIeeHoz2iVolbhrLBj0zS9i4TE7fXM6YMgxWPxXkHHiaQ1FuiyrfTTDB_2RhHt7CGdtemhSvhRjTRJo_q4TphsISJWSWO4vwldc_niaIlu6YlMieXl6KTq6R0tJKm1WSTr2RTgmomn8MWiywo9WSGWE8IpaTM1Y9YApxYFUdtJVSUtJWnHHb6A0vLyThsLlUtIP33zrfZnVBK4L8O6sfMZsCxp9Coyn5ki2nO4bO_ij5GpT4tU2xuKQcLEdTPraoJPaC3lzWxrLuLzCBDVanVQdwnTeStA57wjp5mkIskVAjuHC4QHgY",

      btnHorizontal: true,
      btnCard: true,

      createOrder: (actions) => {
        return actions
          .prepare({
            amount: 999,
            amountWithoutTax: 999,
            currency: "USD",
            clientTransactionId: uniqueId,
            lang: "es",
          })
          .then((paramlog) => {
            return paramlog;
          })
          .catch((paramlog2) => {
            return paramlog2;
          });
      },

      onComplete: (model, actions) => {
        //console.log(model, actions);
      },
    })
    .render("#pp-button");
});
</script>
