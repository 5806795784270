<template>
  <div
    v-if="props.showModal"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <!--content-->
      <div
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
      >
        <!--header-->
        <div
          class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
        >
          <h3 class="text-3xl font-semibold">Productos y servicios</h3>
          <button type="button" class="close" @click="handleCancel">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!--body-->
        <div class="relative p-6 flex-auto">
          <div class="flex flex-wrap w-full">
            <div class="w-full px-2">
              <label class="text-sm"
                >Tipo:<span class="text-red-500">*</span></label
              >
              <select
                class="my-2 px-3 py-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                v-model="itemTypeId"
                :class="{ 'border-red-500': validationErrors.itemTypeId }"
              >
                <option value="" selected>Seleccione...</option>
                <option
                  v-for="itemType in props.itemTypes"
                  :value="itemType.id"
                  :key="itemType.id"
                >
                  {{ itemType.name }}
                </option>
              </select>
              <div
                v-if="validationErrors.type"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.type }}
              </div>
            </div>
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Código:</label>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="code"
                :class="{ 'border-red-500': validationErrors.code }"
              />
              <div
                v-if="validationErrors.code"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.code }}
              </div>
            </div>
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Código Auxiliar:</label>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="aux_code"
                :class="{ 'border-red-500': validationErrors.aux_code }"
              />
              <div
                v-if="validationErrors.aux_code"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.aux_code }}
              </div>
            </div>
            <div class="w-full px-2">
              <label class="text-sm">Nombre:</label>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="name"
                :class="{ 'border-red-500': validationErrors.name }"
              />
              <div
                v-if="validationErrors.name"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.name }}
              </div>
            </div>
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Precio unitario:</label>
              <input
                type="text"
                class="my-2 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                v-model="price"
                :class="{ 'border-red-500': validationErrors.price }"
              />
              <div
                v-if="validationErrors.price"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.price }}
              </div>
            </div>
            <div class="w-full xl:w-6/12 px-2">
              <label class="text-sm">Impuesto:</label>
              <select
                :disabled="default_tax"
                class="my-2 px-3 py-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                v-model="taxId"
                :class="{ 'border-red-500': validationErrors.taxId }"
              >
                <option value="" disabled>Seleccione...</option>
                <option
                  v-for="tax in props.taxes"
                  :value="tax.id"
                  :key="tax.id"
                >
                  {{ tax.taxType.name }} - {{ tax.name }}
                </option>
              </select>
              <div
                v-if="validationErrors.stock"
                class="text-red-500 text-xs mt-1"
              >
                {{ validationErrors.stock }}
              </div>
            </div>
            <div class="w-full px-2">
              <div class="flex items-center my-4">
                <input
                  id="default_tax"
                  type="checkbox"
                  class="w-4 h-4 text-purple-600"
                  v-model="default_tax"
                />
                <label for="default_tax" class="ms-2 text-sm font-medium"
                  >Impuesto por defecto ({{ props.defaultTax.taxType.name }}
                  {{ props.defaultTax.name }})</label
                >
              </div>
            </div>
          </div>
        </div>
        <!--footer-->
        <div
          class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
        >
          <button
            class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="handleCancel"
          >
            Cancelar
          </button>
          <button
            class="bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="handleSave"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="props.showModal"
    class="opacity-25 fixed inset-0 z-40 bg-black"
  ></div>
</template>

<script setup>
import { onUpdated, ref, defineProps, defineEmits } from "vue";
import Swal from "sweetalert2";

import ItemService from "@/services/itemService";

import { clearSession, getBusinessId, showAlertSuccessCreated } from "@/helpers/utils";
import { useRouter } from "vue-router";

const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
  taxes: {
    type: Array,
    required: true,
  },
  defaultTax: {
    type: Object,
    required: true,
  },
  itemTypes: {
    type: Array,
    required: true,
  },
  itemId: {
    type: Number,
    required: false,
  },
});

let edit = false;

const emit = defineEmits(["toggleModal", 'reloadItems']);
const router = useRouter()

const service = new ItemService();

const validationErrors = ref({});
const itemTypeId = ref("");
const taxId = ref("");
const code = ref("");
const aux_code = ref("");
const name = ref("");
const price = ref(0);
const default_tax = ref(true);

onUpdated(() => {
  if (!edit) if (props.itemId !== null) getItem();
});

const getItem = async _ => {
  const reqData = { business_id: getBusinessId() };
  
  try {
    const res = await service.get(props.itemId, reqData);

    const itemType = props.itemTypes.find(type => type.name == res.itemType)

    itemTypeId.value = itemType.id;
    taxId.value = res.taxId;
    code.value = res.code;
    aux_code.value = res.auxCode;
    name.value = res.name;
    price.value = res.unitPrice;
    default_tax.value = res.taxId == props.defaultTax.id;
  } catch (error) {
    const { data, status } = error;

    if (status === 401) {
      if (props.showModal) toggleModal();

      return clearSession(router, true);
    }
  } finally {
    edit = true;
  }
}

const handleSave = async () => {
  const reqData = {
    business_id: getBusinessId(),
    item_type_id: itemTypeId.value,
    tax_id: default_tax.value ? '' : taxId.value,
    name: name.value,
    default_tax: default_tax.value,
    code: code.value,
    aux_code: aux_code.value,
    unit_price: price.value,
  };

  try {
    const res =
      props.itemId !== null
        ? await service.put(props.itemId, reqData)
        : await service.post(reqData);
    await showAlertSuccessCreated();

    edit = false;
    validationErrors.value = {};
    emit('reloadItems');
    handleCancel();
  } catch (error) {
    const { data, status } = error;

    if (status === 401) {
      if (props.showModal) toggleModal();

      return clearSession(router, true);
    }

    if (status == 400 && data.errors && data.errors.validation) {
      await Swal.fire({
        title: "Campos incorrectos y/o faltantes",
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      const { errors } = data;

      let auxErrors = {};
      errors.validation.forEach((validationError) => {
        auxErrors[validationError.field] = validationError.message;
      });

      validationErrors.value = auxErrors;
    }

    if (status == 400 && data.errors && data.errors.constraint_violation) {
      await Swal.fire({
        text: data.errors.constraint_violation.join(', '),
        icon: "warning",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
  }
};

const handleCancel = () => {
  validationErrors.value = {};
  itemTypeId.value = "";
  taxId.value = "";
  code.value = "";
  aux_code.value = "";
  name.value = "";
  price.value = 0;
  default_tax.value = true;

  validationErrors.value = {};

  emit("toggleModal");
};
</script>

<style scoped>
/* Aquí puedes agregar estilos adicionales si es necesario */
</style>
