<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-4/12 px-4 lg:mt-0 mt-4">
      <CardProfile />
    </div>
    <div class="w-full lg:w-8/12 px-4">
      <CardSettings />
    </div>
  </div>
</template>
<script setup>
import { onMounted, } from "vue";
import CardSettings from "@/components/Cards/CardSettings.vue";
import CardProfile from "@/components/Cards/CardProfile.vue";
import Swal from "sweetalert2";

onMounted(() => {
  if (localStorage.getItem('payphone_res') && localStorage.getItem('backend_res')) {
    const payPhoneRes = JSON.parse(localStorage.getItem("payphone_res"))
    const backRes = JSON.parse(localStorage.getItem("backend_res"))
    
    const title = (payPhoneRes.statusCode == 3) ? backRes.message : 'No se recibió el pago';
    const message = (payPhoneRes.statusCode == 3) ? null : payPhoneRes.message ?? '';
    const icon = (payPhoneRes.statusCode == 3) ? 'success' : 'error' ;

    Swal.fire({
      title: title,
      text: message,
      icon: icon
    })

    localStorage.removeItem("payphone_res");
    localStorage.removeItem("backend_res");
  }
})
</script>
